<template>
  <b-modal
    id="is-toggle-modal"
    :visible="isToggleModal"
    centered
    hide-footer
    hide-header
    @hidden="resetForm"
    @hide="resetForm"
    @change="(val) => $emit('update:is-toggle-modal', val)"
  >
    <template #default="{ hide }">
      <b-overlay :show="loading" class="px-2">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header py-1" style="border-bottom: 1px solid #000000;">
          <h5 class="mb-0" style="font-weight: 400; font-size: 14px; line-height: 160%;">
            Application Letter Request
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <div class="my-2">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                :src="getValueFromSource(approvingRequest, 'company.logo')"
                :text="sentenceCase(avatarText(`${getValueFromSource(approvingRequest, 'company.name')}`))"
                :variant="`light-primary`"
              />
            </template>
            <span
              class="font-weight-bold d-block text-nowrap"
            >
              {{ sentenceCase(getValueFromSource(approvingRequest, 'company.name')) }}
            </span>
            <span class="text-muted">{{ getValueFromSource(approvingRequest, 'company.region') }},</span><br />
            <span class="text-muted">{{ getValueFromSource(approvingRequest, 'company.country') }}</span>
          </b-media>
          
          <p>Position: {{ getValueFromSource(approvingRequest, 'options.open_position') }}</p>
          <p>{{ getValueFromSource(approvingRequest, 'options.additional_info') }}</p>
          <hr />
        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="modal_form"
        >
          <b-form
            class="my-1"
            @submit.prevent="handleSubmit(onCreateOrUpdate)"
            @reset.prevent="resetForm"
          >
            <validation-provider
              #default="validationContext"
              name="Upload Application Letter"
              rules="required"
            >
              <b-form-group
                label="Upload Application Letter"
                label-for="name"
              >
                <template #label>
                  <span>Upload Application Letter<sup style="color: tomato">*</sup></span>
                </template>

                <b-form-file
                  id="application_letter_file"
                  v-model="application_letter_file"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  autofocus
                  :disabled="disableForm"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="disableForm"
              >
                Proceed
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import {
  BOverlay, BForm, BFormGroup, BAvatar, BMedia, BFormFile,
  BFormInvalidFeedback, BButton, BModal, BFormInput, BFormTextarea
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
    BModal,
    BMedia,
    BButton,
    vSelect,
    BAvatar, 
    BOverlay,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleModal',
    event: 'update:is-toggle-modal',
  },
  props: {
    isToggleModal: {
      type: Boolean,
      required: true,
    },
    approvingRequest: {
      type: Object,
      required: false,
      default: () => ({}),
    }
  },
  data() {
    return {
      required,
      loading: false,

      application_letter_file: null,
    }
  },
  computed: {
    disableForm(){
      return false
    },
  },
  methods: {
    onCreateOrUpdate(){
      const { application_letter_file } = this;
      this.$emit("send", { application_letter_file, request_id: this.approvingRequest._id });
      this.$emit('update:is-toggle-modal', false);
    },
    resetForm() {
      this.$emit("reset")
      this.application_letter_file = null;
    }
  }
}
</script>
